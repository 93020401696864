import React, { useEffect } from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import Header from "./Header";
import Search from "./Search";
import HeadLine from "./HeadLine";
import Category from "./Category";
import Doctor from "./Doctor";

const createIndexedDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("MyAppDatabase", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create `messages` table if it doesn't exist
      if (!db.objectStoreNames.contains("messages")) {
        db.createObjectStore("messages", { keyPath: "id", autoIncrement: true });
      }

      // Create `loginSession` table if it doesn't exist
      if (!db.objectStoreNames.contains("loginSession")) {
        const loginSessionStore = db.createObjectStore("loginSession", { keyPath: "sessionId" });
        // Add default login session with login set to false
        loginSessionStore.add({ sessionId: "login", login: false });
      }
    };

    request.onsuccess = () => {
      resolve("Database and tables set up successfully with default login session.");
    };

    request.onerror = (error) => {
      reject("Error creating IndexedDB: " + error.target.errorCode);
    };
  });
};

const Home = () => {
  useEffect(() => {
    if (Platform.OS === "web") {
      // Initialize IndexedDB tables when the page is visited
      createIndexedDB()
        .then((message) => console.log(message))
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <ScrollView
      style={{
        paddingHorizontal: 20,
        paddingTop: 10,
        marginTop: Platform.OS === "ios" ? 0 : 30,
      }}
      showsVerticalScrollIndicator={false}
    >
      <Header />
      <Search />
      <HeadLine />
      <Category />
      <Doctor />
    </ScrollView>
  );
};

export default Home;

const styles = StyleSheet.create({});
