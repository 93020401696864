import React, { useState } from "react";
import { View, Text, TextInput, Button, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

const Login = () => {
    const [name, setName] = useState("Login");
    const navigation = useNavigation();

    const handleLogin = async () => {
        const request = indexedDB.open("MyAppDatabase", 1);

        request.onsuccess = () => {
            const db = request.result;
            const transaction = db.transaction(["loginSession"], "readwrite");
            const store = transaction.objectStore("loginSession");

            // Update session to logged in
            store.put({ sessionId: "login", login: true, name });
            navigation.replace("Home"); // Navigate to TabView after logout

            // navigation.navigate("Home"); // Redirect to Profile after login
        };

        request.onerror = (error) => console.error("Failed to open IndexedDB:", error);
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Login</Text>
            <TextInput
                style={styles.input}
                placeholder="Enter your name"
                value={name}
                onChangeText={(text) => setName(text)}
            />
            <Button title="Login" onPress={handleLogin} />
        </View>
    );
};

export default Login;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 20,
    },
    input: {
        width: "100%",
        padding: 10,
        borderColor: "#ccc",
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 20,
    },
});
