import TabView from "./src/screens/TabView";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Details from "./src/screens/Details/Details";
import Profile from "./src/screens/Profile/Profile";
import Login from "./src/screens/Auth/Login";
import Message from "./src/screens/Message/Message";
import Appointment from "./src/screens/Appointment/Appointment";

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{ headerShown: false, animation: "slide_from_right" }}
        initialRouteName="Home"
      >
        <Stack.Screen name="Home" component={TabView} />
        <Stack.Screen name="Appointment" component={Appointment} />
        <Stack.Screen name="Message" component={Message} />
        <Stack.Screen name="Profile" component={Profile} />
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Details" component={Details} />

      </Stack.Navigator>
    </NavigationContainer>
  );
}
