import React, { useEffect, useState } from "react";
import { View, Text, Button, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

// Function to check login session
const checkLoginSession = async () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("MyAppDatabase", 1);

        request.onsuccess = () => {
            const db = request.result;
            const transaction = db.transaction(["loginSession"], "readonly");
            const store = transaction.objectStore("loginSession");
            const getRequest = store.get("login");

            getRequest.onsuccess = () => {
                resolve(getRequest.result);
            };

            getRequest.onerror = () => reject("Failed to retrieve login session.");
        };

        request.onerror = () => reject("Failed to open IndexedDB.");
    });
};

const Message = () => {
    const navigation = useNavigation();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            const session = await checkLoginSession();

            if (session && session.login === true) {
                setProfile({ name: session.name }); // Example profile data
            } else {
                navigation.navigate("Login"); // Redirect to Login if not logged in
            }
        };

        fetchProfile();
    }, [navigation]);


    if (!profile) return null;

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Hi ,{profile.name} this is menu Message </Text>

        </View>
    );
};

export default Message;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 20,
    },
});
